import { useReducer, useState } from "react"

type InputReducerStateType<T> = {
  valid: boolean
  value: T
}

type InputReducerActionType<T> =
  | {
      type: "setValue"
      data: T
    }
  | { type: "setInvalid" }

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const useInputReducer = <T,>(defaultValue: T) =>
  useReducer(
    (state: InputReducerStateType<T>, action: InputReducerActionType<T>) => {
      switch (action.type) {
        case "setValue":
          return {
            ...state,
            valid: true,
            value: action.data,
          }
        case "setInvalid":
          return { ...state, valid: false }
      }
    },
    {
      valid: true,
      value: defaultValue,
    },
  )
/* eslint-enable  @typescript-eslint/no-explicit-any */

export const useModal = () => {
  const [isVisible, setIsVisible] = useState(false)

  function toggleModal() {
    setIsVisible(!isVisible)
  }
  return {
    isVisible,
    toggleModal,
  }
}

export const poundsToInt = (pounds: string) => {
  const parsedPounds = parseInt(pounds.replace(/\D/g, ""), 10)
  return isNaN(parsedPounds) ? 0 : parsedPounds
}

export const intToPounds = (value: number) => {
  const valueString = value.toString()
  const formattedAmount = valueString.replace(/\B(?=(\d{3})+(?!\d))/g, ",") // add a comma after the last group 3 digits (repeated)
  return `£${formattedAmount}`
}

export const stringIntToPounds = (string: string) => {
  return intToPounds(Number(string))
}

export const percentageToFloat = (perc: string) => {
  return parseFloat(perc.replace("%", ""))
}

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

interface IRenameMapperItem {
  name: string
  rename: string
}

interface IObject {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  [key: string]: any
}

// This renameObject should only be used to match API namings
export const renameObjectKeys = (
  object: IObject,
  renameMapper: IRenameMapperItem[],
) => {
  if (typeof object !== "object" || object === null || Array.isArray(object))
    throw Error("renameObjectKeys() requires an iterative object")

  const copy = Object.assign({}, object)

  renameMapper.forEach(({ name, rename }) => {
    if (Object.prototype.hasOwnProperty.call(object, name)) {
      copy[rename] = copy[name]
      delete copy[name]
    }
  })

  return copy
}

export const shadeColor = (color: string, percent: number) => {
  let R = parseInt(color.substring(1, 3), 16)
  let G = parseInt(color.substring(3, 5), 16)
  let B = parseInt(color.substring(5, 7), 16)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  R = parseInt((R * (100 + percent)) / 100)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  G = parseInt((G * (100 + percent)) / 100)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  B = parseInt((B * (100 + percent)) / 100)

  R = R < 255 ? R : 255
  G = G < 255 ? G : 255
  B = B < 255 ? B : 255

  const RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16)
  const GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16)
  const BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16)

  return "#" + RR + GG + BB
}

export const formatIdSafe = (id: string) =>
  id
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^a-z0-9-_]/g, "")
