import React from "react"
import styled from "styled-components"

import { InputInfoVariant } from "components/atoms/InputInfoText"
import ClickTracker from "features/analytics/ClickTracker"

const SInfoCircle = styled.svg<IInfoCircleStyle>`
  fill: ${({ variant, theme }) =>
    variant === "dark" ? theme.color.primary : theme.color.white};
  height: ${({ theme }) => theme.fontSize.small};
`

interface IInfoCircleStyle {
  className?: string
  variant?: InputInfoVariant
}

interface IInfoCircle extends IInfoCircleStyle {
  analyticsID: string
}

export const InfoCircle = ({
  className,
  analyticsID,
  variant = "dark",
}: IInfoCircle) => (
  <ClickTracker analyticsID={analyticsID}>
    <SInfoCircle
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      data-creative-commons-credit="Author: Hea Poh Lin"
      variant={variant}
    >
      <path
        d="M15 2C7.82 2 2 7.82 2 15s5.82 13 13 13 13-5.82 13-13S22.18 2 15 2zm4 21h-8v-3h2v-5h-2v-3h6v8h2v3zm-2-13h-4V7h4v3z"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      />
    </SInfoCircle>
  </ClickTracker>
)

export default InfoCircle
