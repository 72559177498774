import React from "react"
import styled from "styled-components"

import { rems } from "base_css/utils/fontSize"
import { InputInfoVariant } from "components/atoms/InputInfoText"

const SLabel = styled.label<ILabelStyle>`
  color: ${({ theme, variant }) =>
    variant === "light" ? theme.color.white : theme.color.state.error};
  display: block;
  font-size: ${({ theme }) => theme.fontSize.small};
  left: ${rems(19)};
  margin-top: ${({ theme }) => theme.spacing.xSmall};
  text-align: ${({ textAlign }) => textAlign};
`

interface ILabelStyle {
  textAlign?: "left" | "right" | "center"
  variant?: InputInfoVariant
}

interface IInputErrorLabel extends ILabelStyle {
  className?: string
  children?: React.ReactNode
  htmlFor?: string
}

const InputErrorLabel = ({
  className,
  children,
  htmlFor,
  textAlign = "left",
  variant = "dark",
}: IInputErrorLabel) => {
  return (
    <SLabel
      className={className}
      textAlign={textAlign}
      htmlFor={htmlFor}
      variant={variant}
    >
      {children}
    </SLabel>
  )
}

export default InputErrorLabel
