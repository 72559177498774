import React from "react"
import styled, { css } from "styled-components"

import { rems } from "base_css/utils/fontSize"
import { formatIdSafe } from "utils"

const SInput = styled.input<IInputStyle>`
  appearance: none;
  background-color: ${({ theme }) => theme.color.white};
  border: solid 1px ${({ theme }) => theme.color.white};
  border-radius: ${rems(4)};
  box-shadow: 0 ${rems(2)} ${rems(6)} 0 rgba(0, 0, 0, 0.1);
  font-size: ${({ theme }) => theme.fontSize.body};
  height: ${rems(50)};
  ${({ error, theme }) =>
    error &&
    css`
      border: 1px solid ${theme.color.state.error};
    `}

  padding: ${rems(12)} 0 0 ${rems(18)};
  width: 100%;
`

interface IInputStyle {
  error?: boolean
}

type InputMode =
  | "text"
  | "none"
  | "tel"
  | "url"
  | "email"
  | "numeric"
  | "decimal"
  | "search"

export type AutoComplete =
  | "off"
  | "name"
  | "honorific-prefix"
  | "given-name"
  | "additional-name"
  | "family-name"
  | "email"
  | "username"
  | "new-password"
  | "current-password"
  | "one-time-code"
  | "street-address"
  | "street-address1"
  | "street-address2"
  | "street-address3"
  | "country"
  | "bday"
  | "tel"

interface IInput extends IInputStyle {
  className?: string
  id?: string
  testId?: string
  inputMode?: InputMode
  max?: number
  maxLength?: number
  min?: number
  minLength?: number
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  pattern?: string
  placeholder?: string
  type?: string
  value: string
  required?: boolean
  autoComplete?: AutoComplete
  autoCapitalize?: "off" | "on" | "words" | "characters"
}

const Input = ({
  className,
  id,
  inputMode,
  max,
  maxLength,
  min,
  minLength,
  onBlur,
  onChange,
  onFocus,
  onKeyPress,
  pattern,
  placeholder,
  type,
  required,
  value,
  error,
  autoComplete,
  autoCapitalize,
}: IInput) => (
  <SInput
    className={className}
    id={id ? formatIdSafe(id) : undefined}
    data-testid={id ? formatIdSafe(id) : undefined}
    inputMode={inputMode}
    max={max}
    maxLength={maxLength}
    min={min}
    minLength={minLength}
    onBlur={onBlur}
    onChange={onChange}
    onFocus={onFocus}
    onKeyPress={onKeyPress}
    pattern={pattern}
    placeholder={placeholder}
    type={type}
    required={required}
    value={value}
    error={error}
    autoComplete={autoComplete}
    autoCapitalize={autoCapitalize}
  />
)

export default Input
