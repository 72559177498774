import React from "react"
import styled, { css } from "styled-components"

import { rems } from "base_css/utils/fontSize"

const activeLabel = css`
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.fontSize.xSmall};
  line-height: ${rems(11)};
  text-transform: uppercase;
  transform: translateY(-${rems(8)});
`

const SLabel = styled.label<IInputLabelStyle>`
  color: ${({ theme }) => theme.color.grey.darkest};
  font-size: ${({ theme }) => theme.fontSize.body};
  left: ${rems(19)};
  line-height: ${rems(18)};
  ${({ active }) =>
    active &&
    css`
      ${activeLabel};
    `}
  pointer-events: none;
  position: absolute;
  top: ${rems(16)};
  transition: 0.2s ease all;

  input:focus ~ &,
  input:not([value=""]) ~ & {
    ${activeLabel};
  }

  select:focus ~ & {
    ${activeLabel};
  }

  input:-webkit-autofill ~ & {
    ${activeLabel};
  }

  /* stylelint-disable-next-line */
  ${({ error, theme }) =>
    error &&
    css`
      input:not([value=""]):not(:focus) + && {
        color: ${theme.color.state.error};
      }

      input:focus + &&,
      input:not([value=""]):invalid:not(:focus) + && {
        color: ${theme.color.state.error};
      }
      select:focus ~ && {
        color: ${theme.color.state.error};
      }
    `}
`

interface IInputLabelStyle {
  error?: boolean
  active?: boolean
}

interface IInputLabel extends IInputLabelStyle {
  children?: React.ReactNode
  htmlFor?: string
}

const InputLabel = ({ children, htmlFor, error, active }: IInputLabel) => {
  return (
    <SLabel htmlFor={htmlFor} error={error} active={active}>
      {children}
    </SLabel>
  )
}

export default InputLabel
