import React from "react"
import styled from "styled-components"

import { rems } from "base_css/utils/fontSize"
import InfoCircle from "components/svg/InfoCircle"

export type InputInfoVariant = "dark" | "light"

interface IInfoStyle {
  variant?: InputInfoVariant
}

interface IInputInfoText {
  infoText?: string
}

const SInfo = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing.small};
`

const SInfoCircle = styled(InfoCircle)`
  margin-right: ${({ theme }) => theme.spacing.xSmall};
  margin-top: ${rems(1)}; /* baseline font fix */
`

const SInfoText = styled.span<IInfoStyle>`
  color: ${({ variant, theme }) =>
    variant === "dark" ? theme.color.primary : theme.color.white};
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.small};
  text-align: left;
`

interface IInputInfoText extends IInfoStyle {
  infoText?: string
}

const InputInfoText = ({ infoText, variant = "dark" }: IInputInfoText) => (
  <SInfo>
    <SInfoCircle variant={variant} analyticsID="InputInfoText InfoCircle" />
    <SInfoText variant={variant}>{infoText}</SInfoText>
  </SInfo>
)

export default InputInfoText
