import React, { useState } from "react"
import styled from "styled-components"

import Input from "components/atoms/Input"
import InputErrorLabel from "components/atoms/InputErrorLabel"
import InputLabel from "components/atoms/InputLabel"
import InputInfoText from "components/atoms/InputInfoText"
import { InputInfoVariant } from "components/atoms/InputInfoText"
import { intToPounds, poundsToInt, formatIdSafe } from "utils"

interface IInputPounds {
  label?: string
  required?: boolean
  value: string
  error?: boolean
  customErrorMessage?: string
  min?: number
  max?: number
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void
  infoText?: string
  infoTextVariant?: InputInfoVariant
}

const SDiv = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`

const InputPounds = ({
  label,
  onChange,
  onBlur,
  required,
  value,
  error,
  customErrorMessage,
  min,
  max,
  infoText,
  infoTextVariant,
}: IInputPounds) => {
  const [focused, setFocused] = useState<boolean>(false)

  const formatAmount = (value: string) => {
    const formattedAmount = value
      .replace(/\D/g, "") // remove any character that's not a digit [0-9]
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") // add a comma after the last group 3 digits (repeated)
    const pound = formattedAmount || focused ? "£" : ""
    const formattedValue = pound + formattedAmount
    return formattedValue
  }

  const parsedPounds = poundsToInt(value)
  let errorMessage
  if (customErrorMessage) {
    errorMessage = customErrorMessage
  } else if (max && parsedPounds > max) {
    errorMessage = `The maximum value is ${intToPounds(max)}`
  } else if (min !== undefined && parsedPounds < min) {
    errorMessage = `The minimum value is ${intToPounds(min)}`
  } else if (error) {
    errorMessage = label
      ? `Please enter a valid ${label.toLowerCase()}`
      : "Invalid input"
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatAmount(event.target.value)
    event.target.value = formattedValue
    onChange(event)
  }

  const onFocus = () => {
    setFocused(true)
  }

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFocused(false)
    if (onBlur) {
      onBlur(event)
    }
  }

  return (
    <SDiv>
      <Input
        id={label ? formatIdSafe(label) : undefined}
        inputMode="decimal"
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={handleBlur}
        required={required}
        type="text"
        error={error}
        value={formatAmount(value)}
        pattern="(^£$)|(^£\d{1,3}(,\d{3})*?$)"
      />
      <InputLabel htmlFor={label ? formatIdSafe(label) : undefined}>
        {label}
      </InputLabel>
      {infoText && (
        <InputInfoText variant={infoTextVariant} infoText={infoText} />
      )}
      {errorMessage && (
        <InputErrorLabel variant={infoTextVariant}>
          {errorMessage}
        </InputErrorLabel>
      )}
    </SDiv>
  )
}

export default InputPounds
