import React from "react"
import styled, { css } from "styled-components"

import { breakpoint } from "base_css/vars"

interface IBreakpointStyle {
  showFor: keyof typeof breakpoint
  display?:
    | "inline"
    | "block"
    | "contents"
    | "flex"
    | "grid"
    | "inline-block"
    | "inline-flex"
    | "inline-grid"
    | "inline-table"
    | "list-item"
    | "run-in"
    | "table"
    | "table-caption"
    | "table-column-group"
    | "table-header-group"
    | "table-footer-group"
    | "table-row-group"
    | "table-cell"
    | "table-column"
    | "table-row"
    | "none"
    | "initial"
    | "inherit"
}

const SBreakpoint = styled.div<IBreakpointStyle>`
  ${({ showFor, display }) =>
    showFor
      ? css`
          display: none;
          ${breakpoint[showFor]} {
            display: ${display};
          }
        `
      : css`
          display: ${display};
        `}
`

interface IBreakpoint extends IBreakpointStyle {
  children: React.ReactNode
}

const Breakpoint = ({ children, showFor, display = "block" }: IBreakpoint) => {
  return (
    <SBreakpoint showFor={showFor} display={display}>
      {children}
    </SBreakpoint>
  )
}

export default Breakpoint
